<template>
  <section class="container my-24 my-lg-40">
    <div class="row">
      <header class="col">
        <h2>Личный кабинет</h2>
      </header>
    </div>

    <section class="row mt-24">
      <div class="col">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <div v-show="showInstruction">
                <p>
                  <b>Уважаемый коллега!</b>
                </p>

                <p>
                  Перед Вами — список сотрудников, по которым Вам необходимо предоставить обратную связь «360 градусов».
                  Если Вы получаете обратную связь (помимо роли респондента для Ваших коллег), неоходимо будет пройти
                  <b>самооценку</b>.
                </p>

                <hr class="my-32">

                <h3>Советы и рекомендации:</h3>

                <div class="row my-32">
                  <div class="col-12 col-sm-6 mb-12">
                    <img class="mb-12" src="@/assets/images/icons/360/colored/clock.svg" alt="">
                    <p>
                      <b>Заполнение одного опросника занимает не более 10 минут</b>. Постарайтесь спланировать для себя
                      график
                      заполнения.
                    </p>
                  </div>

                  <div class="col-12 col-sm-6 mb-12">
                    <img class="mb-12" src="@/assets/images/icons/360/colored/tie.svg" alt="">
                    <p>
                      <b>Будьте честны.</b> Отмечайте только те идикаторы, которые Вы точно наблюдали в профессиональной
                      деятельности сотрудника.
                    </p>
                  </div>

                  <div class="col-12 col-sm-6 mb-12">
                    <img class="mb-12" src="@/assets/images/icons/360/colored/list.svg" alt="">
                    <p>
                      <b>Первое впечатление обычно наиболее верное</b>. Выбирайте индикаторы поведения, которые по
                      ощущениям отзываются первыми.
                    </p>
                  </div>

                  <div class="col-12 col-sm-6 mb-12">
                    <img class="mb-12" src="@/assets/images/icons/360/colored/message.svg" alt="">
                    <p>
                      <b>Не стремитесь угадать оценку, которую поставят другие</b>. Старайтесь приводить примеры и
                      конкретные комментарии.
                    </p>
                  </div>

                  <div class="col-12 col-sm-6 mb-12">
                    <img class="mb-12" src="@/assets/images/icons/360/colored/aim.svg" alt="">
                    <p>
                      <b>Избегайте нейтральных оценок</b>. Гораздо полезнее получить отчет с четким указанием зон роста
                      и сильных сторон.
                    </p>
                  </div>

                  <div class="col-12 col-sm-6 mb-12">
                    <img class="mb-12" src="@/assets/images/icons/360/colored/medal.svg" alt="">
                    <p>
                      <b>Получайте награды за ответы</b>. Ваши оценки помогаю коллегам становится лучше!
                    </p>
                  </div>
                </div>
              </div>

              <v-button
                @click="showInstruction = !showInstruction"
                link
              >
                {{ showInstruction ? 'Скрыть' : 'Посмотреть' }} инструкцию
                <v-icon-svg
                  class="a-flip-y svg-stroke-ui-main-1 ml-8"
                  :class="{'a-flip-y_active': showInstruction}"
                  style="width: .75rem; height: .75rem;"
                  fill="none"
                  view-box="0 0 11 5"
                  title="svg-icon-chevron"
                  width="12"
                  height="12"
                >
                  <svg-icon-chevron />
                </v-icon-svg>
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </section>

    <section class="row justify-content-between align-items-center mt-40">
      <div class="col-auto">
        <v-icon-svg
          class="mr-12"
          fill="#FFCA2B"
          view-box="0 0 40 32"
          title="svg-icon-chevron"
          width="3.125rem"
          height="2.5rem"
        >
          <svg-icon360-crown/>
        </v-icon-svg>

        <b>90</b>
      </div>

      <div class="col-auto">
        <v-icon-svg
          class="svg-fill-ui-main-1 mr-12"
          fill="#FFCA2B"
          view-box="0 0 24 24"
          title="svg-icon-chevron"
          width="1.5rem"
          height="1.5rem"
        >
          <svg-icon360-sert/>
        </v-icon-svg>

        <b class="text-color-primary">Мой отчет 360°</b>
      </div>
    </section>

    <section class="row mt-32">
      <div class="col">
        <v-tabs>
          <v-tab
            v-for="(tab, index) in content.tabs"
            :key="index"
            :label="tab.name"
          >
            <div class="p-32">
              <div class="row mb-40 align-items-center">
                <div class="col">
                  <h2>
                    {{tab.name}}
                  </h2>
                </div>

                <div class="col-auto">
                  <button type="button" class="btn-add-user">
                    <span class="btn-add-user__icon">
                      <v-icon-svg
                        stroke="none"
                        fill="light"
                        view-box="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                        style="top: 0"
                      >
                        <svg-icon360-add-user/>
                      </v-icon-svg>
                    </span>

                    <span class="btn-add-user__text">
                      Добавить коллег<br/>для оценки
                    </span>
                  </button>
                </div>
              </div>

              <div
                v-for="tabGroup of tab.groups"
                :key="tabGroup.name"
              >
                <h4
                  v-if="!tabGroup.hide_name_flag"
                  class="mt-32 mb-12 text-color-text-secondary"
                >
                  {{ tabGroup.name }}
                </h4>

                <v-list
                  underlined
                >
                  <v-list-item
                    v-for="(tabGroupLink, index) of tabGroup.links"
                    :ripple="null"
                    :key="index"
                    :class="{
                      'v-list-item_is_active': status[tabGroupLink.uuid] === 'end',
                      'v-list-item_is_clickable': tab.clickable
                    }"
                    class="px-16"
                  >
                    <div class="text-bold">{{ tabGroupLink.text }}</div>
                    <div class="text-size-14 text-color-text-secondary mt-4">Должность 2</div>

                    <template
                      v-if="tab.clickable"
                      slot="end"
                    >
                      <div class="list-button">
                        <div
                          v-if="status[tabGroupLink.uuid] === 'end'"
                          title="Опрос завершен"
                        >
                          <v-icon-svg
                            stroke="none"
                            fill="var(--color-primary)"
                            view-box="0 0 24 13"
                            width="24"
                            height="24"
                          >
                            <svg-icon360-checked/>
                          </v-icon-svg>
                        </div>

                        <v-button
                          v-if="status[tabGroupLink.uuid] === 'not started'"
                          round
                          icon
                          size="sm"
                          color="success"
                          title="Заполнить опросник"
                        >
                          <v-icon-svg
                            style="left: 1px; top: 0; width: .75rem; height: .75rem;"
                            stroke="none"
                            fill="light"
                            view-box="0 0 9 12"
                            width="12"
                            height="12"
                          >
                            <svg-icon360-play/>
                          </v-icon-svg>
                        </v-button>

                        <v-button
                          v-if="status[tabGroupLink.uuid] === 'progress'"
                          round
                          icon
                          size="sm"
                          color="warning"
                          title="Продолжить"
                        >
                          <v-icon-svg
                            style="top: 0; width: .75rem; height: .75rem;"
                            stroke="light"
                            fill="none"
                            view-box="0 0 8 12"
                            width="12"
                            height="12"
                          >
                            <svg-icon360-pause/>
                          </v-icon-svg>
                        </v-button>
                      </div>

                      <div class="list-button">
                        <v-button
                          v-if="status[tabGroupLink.uuid] !== 'end'"
                          round
                          icon
                          size="sm"
                          transparent
                          color="secondary"
                          title="Удалить"
                        >
                          <v-icon-svg
                            style="top: 0; width: .75rem; height: .75rem;"
                            stroke="none"
                            fill="var(--color-border)"
                            view-box="0 0 9 9"
                            width="12"
                            height="12"
                          >
                            <svg-icon-x/>
                          </v-icon-svg>
                        </v-button>
                      </div>
                    </template>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </section>
  </section>
</template>

<script>
import { VButton, VCard, VList, VListItem, VIconSvg, VTab, VTabs } from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import SvgIcon360Crown from '@components/icons/360/SvgIcon360Crown'
import SvgIcon360Sert from '@components/icons/360/SvgIcon360Sert'
import SvgIcon360Checked from '@components/icons/360/SvgIcon360Checked'
import SvgIcon360Play from '@components/icons/360/SvgIcon360Play'
import SvgIcon360Pause from '@components/icons/360/SvgIcon360Pause'
import SvgIcon360AddUser from '@components/icons/360/SvgIcon360AddUser'
// import SvgIcon360Lock from '@components/icons/360/SvgIcon360Lock'
import SvgIconX from '@components/icons/SvgIconX'

export default {
  name: 'S360List',

  components: {
    SvgIcon360AddUser,
    SvgIcon360Checked,
    SvgIcon360Crown,
    SvgIcon360Pause,
    SvgIcon360Play,
    // SvgIcon360Lock,
    SvgIcon360Sert,
    SvgIconX,
    SvgIconChevron,
    VButton,
    VCard,
    VIconSvg,
    VList,
    VListItem,
    VTab,
    VTabs
  },

  data () {
    return {
      showInstruction: false,
      content: {
        instruction: 'Это HTML инструкция к опросу 360',
        tabs: [
          {
            name: 'Список оцениваемых',
            clickable: true,
            groups: [
              {
                name: 'Самооценка',
                hide_name_flag: true,
                links: [
                  {
                    text: 'Самооценка',
                    uuid: '8936b4c5-5a5b-4d95-84bf-163ef8643665'
                  }
                ]
              },
              {
                name: 'Коллеги',
                hide_name_flag: false,
                links: [
                  {
                    text: 'Буратино45',
                    uuid: '61ebac80-7481-43cc-a09d-659584775557'
                  },
                  {
                    text: 'Винни-Пух',
                    uuid: '6f61807f-7bf8-48e6-9cc9-c47650bc784e'
                  },
                  {
                    text: 'Герда',
                    uuid: '0a42bb61-b028-43d8-875d-d5d655f3f936'
                  },
                  {
                    text: 'Гулливер',
                    uuid: 'ea05d7b5-66d7-4663-bd8a-2f2846473ac4'
                  },
                  {
                    text: 'Алиса Селезнёва',
                    uuid: 'db61ca62-e51c-4be0-84a5-1eb506ff0c0d'
                  },
                  {
                    text: 'Барон Мюнхгаузен',
                    uuid: '13a8cbb6-2204-466f-8896-2cb42a571c7c'
                  },
                  {
                    text: 'Бильбо Бэггинс',
                    uuid: '5b56502d-b292-4df7-b78d-c7d1d63213ed'
                  },
                  {
                    text: 'Василиса Прекрасная',
                    uuid: 'c6f3deed-8a3e-4671-bf89-56860e5270a0'
                  },
                  {
                    text: 'Вилли Вонка',
                    uuid: '6888309f-e11f-4c5b-8050-00aa3a796284'
                  },
                  {
                    text: 'Гарри Поттер',
                    uuid: 'c03c681f-31af-4b54-850e-91b26e63d6b5'
                  },
                  {
                    text: 'Гекльберри Финн',
                    uuid: '133e3ee3-0a11-45fd-9fe9-4fc1b8e06fb5'
                  },
                  {
                    text: 'Дениска Кораблев',
                    uuid: 'f1b72ffa-7ecd-4f18-8891-69b5a5c71aba'
                  },
                  {
                    text: 'Джим Хокинс',
                    uuid: '0d541fc4-24b0-4bf7-a921-4d4d80512bc1'
                  },
                  {
                    text: 'Северус Снегг',
                    uuid: 'ed37e495-be16-437b-a99b-b818745b44d9'
                  }
                ]
              },
              {
                name: 'Подчиненные',
                hide_name_flag: false,
                links: [
                  {
                    text: 'Василиса Премудрая',
                    uuid: 'd77167ac-f0cc-4511-b81f-7f0ce6890c6d'
                  }
                ]
              },
              {
                name: 'Руководители',
                hide_name_flag: false,
                links: [
                  {
                    text: 'Маугли3',
                    uuid: '455d1ec2-4161-4229-ab96-44a89a2063e4'
                  },
                  {
                    text: 'Мумми-тролль',
                    uuid: '6e72dbe0-602b-4d96-a2e9-4b795b473973'
                  },
                  {
                    text: 'Незнайка3',
                    uuid: '0000fa2d-77fb-4edf-85fc-3cd4b15da699'
                  },
                  {
                    text: 'Нильс',
                    uuid: '81204e7f-918b-43ac-acb3-229ed2b3e8d5'
                  },
                  {
                    text: 'Оле-Лукойе',
                    uuid: '1b2b4e02-4b92-4de6-a699-0751b46597a9'
                  },
                  {
                    text: 'Пиноккио',
                    uuid: 'c9570b49-57ac-4e57-a79c-8fd4d8c18e03'
                  },
                  {
                    text: 'Русалочка',
                    uuid: '4ec789c4-f068-420e-9090-03d52e3ac421'
                  },
                  {
                    text: 'Ухти-Тухти',
                    uuid: '31f5a962-2b43-4148-a48e-b65cae077c81'
                  },
                  {
                    text: 'Хоттабыч',
                    uuid: '0f413a30-10ff-4809-9e52-3172fd142573'
                  },
                  {
                    text: 'Чебурашка',
                    uuid: '81ad4b68-1a7c-4e08-b4d2-f1f3940e0132'
                  },
                  {
                    text: 'Чиполлино',
                    uuid: '88309690-bac8-4f41-abeb-7127379d1b77'
                  },
                  {
                    text: 'Щелкунчик',
                    uuid: '22230ec6-b64e-40f1-926a-86c9e51b83f6'
                  },
                  {
                    text: 'Электроник',
                    uuid: '069c138d-de98-48e7-a0e1-e4e498fff637'
                  },
                  {
                    text: 'Доктор Айболит',
                    uuid: '87dc21a3-b6f3-4caf-9e70-44bdcd67159a'
                  },
                  {
                    text: 'Мэри Поппинс',
                    uuid: 'd6e06778-52bb-4a71-bc5e-c7d19e72d39a'
                  },
                  {
                    text: 'Оловянный солдатик',
                    uuid: '559689d9-6963-4663-b481-b1ca5ae469eb'
                  },
                  {
                    text: 'Пеппи-длинный чулок',
                    uuid: '7c2beded-ccdb-4b18-be4f-4f33d5760bc1'
                  },
                  {
                    text: 'Питер Пен',
                    uuid: '2a5f730a-7e41-4c70-9b4d-1eab8887df07'
                  },
                  {
                    text: 'Робинзон Крузо',
                    uuid: '8a4f1d40-28c3-45ce-9a79-1bf44f5208bc'
                  },
                  {
                    text: 'Собака Шарик',
                    uuid: '840f8190-c8f3-46df-90e6-d4a28d5debdb'
                  },
                  {
                    text: 'Тим Талер',
                    uuid: 'b33a4830-de4f-4934-a3c3-fe9b1b80b89f'
                  },
                  {
                    text: 'Том Сойер',
                    uuid: '50280afa-adf4-471c-9941-edc3a215180d'
                  }
                ]
              }
            ]
          },
          {
            name: 'Кто меня оценивает',
            clickable: false,
            groups: [
              {
                name: '',
                hide_name_flag: true,
                links: [
                  {
                    text: 'Нильс',
                    uuid: ''
                  },
                  {
                    text: 'Маугли3',
                    uuid: ''
                  },
                  {
                    text: 'Собака Шарик',
                    uuid: ''
                  },
                  {
                    text: 'Хоттабыч',
                    uuid: ''
                  },
                  {
                    text: 'Доктор Айболит',
                    uuid: ''
                  },
                  {
                    text: 'Электроник',
                    uuid: ''
                  },
                  {
                    text: 'Ухти-Тухти',
                    uuid: ''
                  },
                  {
                    text: 'Робинзон Крузо',
                    uuid: ''
                  },
                  {
                    text: 'Незнайка3',
                    uuid: ''
                  },
                  {
                    text: 'Том Сойер',
                    uuid: ''
                  },
                  {
                    text: 'Пиноккио',
                    uuid: ''
                  },
                  {
                    text: 'Щелкунчик',
                    uuid: ''
                  },
                  {
                    text: 'Тим Талер',
                    uuid: ''
                  },
                  {
                    text: 'Питер Пен',
                    uuid: ''
                  },
                  {
                    text: 'Мумми-тролль',
                    uuid: ''
                  },
                  {
                    text: 'Русалочка',
                    uuid: ''
                  },
                  {
                    text: 'Пеппи-длинный чулок',
                    uuid: ''
                  },
                  {
                    text: 'Чиполлино',
                    uuid: ''
                  },
                  {
                    text: 'Оловянный солдатик',
                    uuid: ''
                  },
                  {
                    text: 'Мэри Поппинс',
                    uuid: ''
                  },
                  {
                    text: 'Оле-Лукойе',
                    uuid: ''
                  },
                  {
                    text: 'Чебурашка',
                    uuid: ''
                  },
                  {
                    text: 'Дениска Кораблев',
                    uuid: ''
                  },
                  {
                    text: 'Василиса Прекрасная',
                    uuid: ''
                  },
                  {
                    text: 'Гулливер',
                    uuid: ''
                  },
                  {
                    text: 'Винни-Пух',
                    uuid: ''
                  },
                  {
                    text: 'Герда',
                    uuid: ''
                  },
                  {
                    text: 'Вилли Вонка',
                    uuid: ''
                  },
                  {
                    text: 'Буратино45',
                    uuid: ''
                  },
                  {
                    text: 'Бильбо Бэггинс',
                    uuid: ''
                  },
                  {
                    text: 'Гекльберри Финн',
                    uuid: ''
                  },
                  {
                    text: 'Барон Мюнхгаузен',
                    uuid: ''
                  },
                  {
                    text: 'Северус Снегг',
                    uuid: ''
                  },
                  {
                    text: 'Алиса Селезнёва',
                    uuid: ''
                  },
                  {
                    text: 'Джим Хокинс',
                    uuid: ''
                  },
                  {
                    text: 'Гарри Поттер',
                    uuid: ''
                  },
                  {
                    text: 'Василиса Премудрая',
                    uuid: ''
                  }
                ]
              }
            ]
          }
        ]
      },
      status: {
        '0000fa2d-77fb-4edf-85fc-3cd4b15da699': 'progress',
        '069c138d-de98-48e7-a0e1-e4e498fff637': 'not started',
        '0a42bb61-b028-43d8-875d-d5d655f3f936': 'end',
        '0d541fc4-24b0-4bf7-a921-4d4d80512bc1': 'not started',
        '0f413a30-10ff-4809-9e52-3172fd142573': 'not started',
        '133e3ee3-0a11-45fd-9fe9-4fc1b8e06fb5': 'progress',
        '13a8cbb6-2204-466f-8896-2cb42a571c7c': 'end',
        '1b2b4e02-4b92-4de6-a699-0751b46597a9': 'not started',
        '22230ec6-b64e-40f1-926a-86c9e51b83f6': 'not started',
        '2a5f730a-7e41-4c70-9b4d-1eab8887df07': 'not started',
        '31f5a962-2b43-4148-a48e-b65cae077c81': 'not started',
        '455d1ec2-4161-4229-ab96-44a89a2063e4': 'not started',
        '4ec789c4-f068-420e-9090-03d52e3ac421': 'not started',
        '50280afa-adf4-471c-9941-edc3a215180d': 'end',
        '559689d9-6963-4663-b481-b1ca5ae469eb': 'not started',
        '5b56502d-b292-4df7-b78d-c7d1d63213ed': 'end',
        '61ebac80-7481-43cc-a09d-659584775557': 'end',
        '6888309f-e11f-4c5b-8050-00aa3a796284': 'end',
        '6e72dbe0-602b-4d96-a2e9-4b795b473973': 'not started',
        '6f61807f-7bf8-48e6-9cc9-c47650bc784e': 'end',
        '7c2beded-ccdb-4b18-be4f-4f33d5760bc1': 'not started',
        '81204e7f-918b-43ac-acb3-229ed2b3e8d5': 'not started',
        '81ad4b68-1a7c-4e08-b4d2-f1f3940e0132': 'not started',
        '840f8190-c8f3-46df-90e6-d4a28d5debdb': 'not started',
        '87dc21a3-b6f3-4caf-9e70-44bdcd67159a': 'not started',
        '88309690-bac8-4f41-abeb-7127379d1b77': 'not started',
        '8936b4c5-5a5b-4d95-84bf-163ef8643665': 'end',
        '8a4f1d40-28c3-45ce-9a79-1bf44f5208bc': 'not started',
        'b33a4830-de4f-4934-a3c3-fe9b1b80b89f': 'not started',
        'c03c681f-31af-4b54-850e-91b26e63d6b5': 'end',
        'c6f3deed-8a3e-4671-bf89-56860e5270a0': 'end',
        'c9570b49-57ac-4e57-a79c-8fd4d8c18e03': 'not started',
        'd6e06778-52bb-4a71-bc5e-c7d19e72d39a': 'not started',
        'd77167ac-f0cc-4511-b81f-7f0ce6890c6d': 'not started',
        'd7d65a2b-53cd-4c2e-9976-c26b0cc99cce': 'progress',
        'db61ca62-e51c-4be0-84a5-1eb506ff0c0d': 'end',
        'ea05d7b5-66d7-4663-bd8a-2f2846473ac4': 'end',
        'ed37e495-be16-437b-a99b-b818745b44d9': 'not started',
        'f1b72ffa-7ecd-4f18-8891-69b5a5c71aba': 'end'
      }
    }
  },

  computed: {
    filteredContent () {
      return this.content
    }
  }
}
</script>

<style lang="scss">
@import "~@styles/variables";
@import "~@styles/tools";

.list-button {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 3rem;
}

.btn-add-user {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: br(null);
  cursor: pointer;
  transition: color .3s ease;

  &:hover {
    color: cl(primary);
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: cl(primary);
    border-radius: br(null);
  }
  &__text {
    padding: 0 .75rem;
    text-align: left;
    font: 600 1rem $font-primary;
  }
}
</style>
